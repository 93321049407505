<template>
  <div class="statusBox" v-if="orderId">
    <v-row class="ma-0">
      <v-col :cols="3" class="d-flex justify-center">
        <v-img src="../assets/image/Success.png" contain width="40" height="40" />
      </v-col>
      <v-col :cols="9" class="pa-0 d-flex justify-center align-center text-center">
        <div>
          <span class="titleName">
            การสั่งซื้อหมายเลข
            <span class="headerBold">#{{ orderId }}</span>
          </span>
          <span class="titleName">
            ได้เพิ่มในกระบวนการสั่งซื้อเป็นที่เรียบร้อย
          </span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SaleOrderHeader',
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped></style>
