<template>
  <v-content>
    <div>
      <v-tabs v-model="tab" fixed-tabs centered slider-size="3" background-color="#191f28" dark>
        <v-tab> รอตรวจสอบ </v-tab>
        <v-tab> รอชำระเงิน </v-tab>
        <v-tab> สำเร็จ </v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in 3" :key="item">
            <v-container>
              <v-expansion-panels>
                <v-expansion-panel v-for="(v, index) in order" :key="index" class="statusBox mt-1">
                  <v-expansion-panel-header class="pa-1">
                    <v-row class="ma-0">
                      <v-col :cols="2" class="d-flex justify-center">
                        <v-img src="../assets/image/clock.png" contain width="40" height="40" />
                      </v-col>
                      <v-col :cols="10" class="pa-0 d-flex align-center justify-space-between">
                        <div class="justify-start">
                          <div>
                            <span class="titleName">
                              ร้านค้า: {{ v.merchant_detail[0].name_store }}
                            </span>
                            <span class="titleName" style="color: gray">
                              {{ date(v.date_time) }}
                            </span>
                            <span
                              class="titleName"
                              style="color: #f7d83c;"
                              v-if="v.status === 'waiting_payment_upload'"
                            >
                              รอแจ้งการชำระเงิน
                            </span>
                            <span
                              class="titleName"
                              style="color: #50a05b;"
                              v-if="v.status === 'payment_uploaded'"
                            >
                              แจ้งการชำระเงินแล้ว
                            </span>
                            <span
                              class="titleName"
                              style="color: #ff7777;"
                              v-if="v.status === 'payment_deny'"
                            >
                              ชำระเงินไม่ผ่าน
                            </span>
                          </div>
                        </div>
                        <div>
                          <div class="priceBox mb-1 d-flex justify-center">
                            <span class="price pa-1">
                              THB {{ v.grand_total.toLocaleString() }}
                            </span>
                          </div>
                          <div class="companyBox d-flex justify-center">
                            <span class="price pa-1 px-3">
                              {{ v.type === 'saleorder' ? 'Brewberry' : 'NIDA' }}
                            </span>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <SaleOrder expand :status="status" :order="v" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </v-content>
</template>

<script>
import SaleOrder from '../components/SaleOrder'
export default {
  name: 'OrderStatus',
  data() {
    return {
      tab: null,
      status: '',
      order: [],
    }
  },
  components: {
    SaleOrder,
  },
  methods: {
    async getOrderList() {
      const self = this
      try {
        const res = await self.$axios({
          method: 'GET',
          url: '/api/salesorder/orderlist',
          params: {
            social_id: self.$store.state.tokenId,
            status: self.status,
          },
        })
        self.order = res.data[0]
      } catch (e) {
        self.$modal.show({
          title: `ERROR: ${e}`,
          isOne: true,
        })
      }
    },
  },
  watch: {
    tab(n) {
      this.order = []
      if (n === 0) {
        this.status = 'waiting_for_confirmation'
        this.getOrderList()
      } else if (n === 1) {
        this.status = 'payment_check'
        this.getOrderList()
      } else if (n === 2) {
        this.status = 'approved'
        this.getOrderList()
      }
    },
  },
  computed: {
    date() {
      return v => this.$dayjs(v).format('YYYY-MM-DD HH:mm')
    },
  },
}
</script>

<style lang="scss" scoped>
.price {
  display: block;
  font-family: db_heaventregular;
  font-size: 14px;
  color: #ffffff;
}
.priceBox {
  background-color: var(--v-primary3-base);
}
.companyBox {
  background-color: var(--v-primary2-base);
}
</style>
