<template>
  <div>
    <SaleOrderHeader :orderId="order.sales_order" v-if="isHeader"></SaleOrderHeader>
    <div class="orderHeader">
      <div class="d-flex justify-space-between pa-2 px-3">
        <div>
          <span class="paymentText" style="font-size: 20px">{{ checktype }}</span>
        </div>
        <div>
          <span class="paymentText" style="font-size: 20px"> #{{ order.sales_order }} </span>
        </div>
      </div>
    </div>
    <div class="statusBox">
      <v-row class="header ma-0">
        <v-col :cols="4" class="table">
          <span class="titleName">รายการ</span>
        </v-col>
        <v-col :cols="3" class="table">
          <span class="titleName"> ราคา </span>
        </v-col>
        <v-col :cols="2" class="table">
          <span class="titleName"> จำนวน </span>
        </v-col>
        <v-col :cols="3" class="table">
          <span class="titleName"> ราคาสุทธิ </span>
        </v-col>
      </v-row>
      <v-row
        class="ma-0"
        v-for="(v, index) in order.products"
        :key="index"
        style="background-color: #fff"
      >
        <v-col :cols="4" class="table py-2 px-1">
          <div>
            <span class="Name">{{ v.name }}</span>
            <span class="description">{{ v.description }}</span>
          </div>
        </v-col>
        <v-col :cols="3" class="table">
          <span class="description"
            >THB
            {{ v.unit_price.toLocaleString() }}
          </span>
        </v-col>
        <v-col :cols="2" class="table">
          <span class="description">
            {{ v.qty }}
          </span>
        </v-col>
        <v-col :cols="3" class="table">
          <span class="description"> THB {{ v.subtotal.toLocaleString() }} </span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-container
        v-for="v in order.merchant_detail"
        :key="v.store_id"
        style="background-color: #fff"
      >
        <v-row class="ma-0">
          <v-col :cols="6" class="pa-0">
            <span class="subjectName">ชื่อร้านค้า: </span>
            <span class="detailName">{{ v.name_store }}</span>
          </v-col>
          <v-col :cols="6" class="pa-0">
            <span class="subjectName">เบอร์โทรศัพท์: </span>
            <span class="detailName">{{ v.account_phone_1 }}</span>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col :cols="6" class="pa-0">
            <span class="subjectName">ชื่อผู้สั่งซื้อ: </span>
            <span class="detailName">{{ v.account_name }}</span>
          </v-col>
          <v-col :cols="6" class="pa-0">
            <span class="subjectName">อีเมล: </span>
            <span class="detailName">{{ v.account_email_1 }}</span>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col :cols="6" class="pa-0">
            <span class="subjectName">ที่อยู่: </span>
            <span class="detailName">{{ address }}</span>
          </v-col>
          <v-col :cols="6" class="pa-0">
            <span class="subjectName">คำอธิบายเพิ่มเติม: </span>
            <span class="detailName">{{ order.description || '-' }}</span>
          </v-col>
        </v-row>
      </v-container>
      <div class="paymentBox">
        <v-row class="ma-0">
          <v-col :cols="7" class="borderRight pa-1">
            <span class="paymentText d-flex justify-left ml-2" v-if="order.payment_type === 'cash'">
              ชำระเงิน: โอนเงิน
            </span>
            <span class="paymentText d-flex justify-left ml-2" v-else>
              ชำระเงิน: เครดิตร้านค้า
            </span>
            <div v-if="order.payment_type === 'cash'">
              <div class="d-flex justify-center" v-if="order.bank_accounts[0]">
                <div>
                  <span class="bankDetail">
                    เลขที่บัญชี:
                    {{ order.bank_accounts[0].bank_account_number }}
                  </span>
                  <span class="bankDetail"> ธนาคาร:{{ order.bank_accounts[0].bank_name }} </span>
                  <span class="bankDetail">
                    ชื่อบัญชี:{{ order.bank_accounts[0].bank_account_name }}
                  </span>
                </div>
              </div>
            </div>
          </v-col>
          <v-col :cols="5" class="pa-0 totalPriceBox">
            <div class="d-flex justify-space-between pa-1">
              <div>
                <span class="subjectPrice">ยอดรวม</span>
                <span class="subjectPrice">
                  ภาษี 7%
                </span>
              </div>
              <div>
                <span class="bankDetail text-right">
                  THB
                  {{
                    order.grand_total.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }}
                </span>
                <span class="bankDetail text-right">
                  THB
                  {{
                    ((order.grand_total * 7) / 100).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }}
                </span>
              </div>
            </div>
            <v-container
              style="background-color: #5a5a5a"
              class="d-flex justify-space-between align-center pa-1"
            >
              <span class="subjectPrice">ยอดรวม</span>
              <span class="subjectPrice text-right" style="font-size:18px;">
                THB
                {{
                  (order.grand_total + (order.grand_total * 7) / 100).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </span>
            </v-container>
          </v-col>
        </v-row>
      </div>
      <div :class="checkExpand">
        <span class="alertText text-center">
          **กรุณาชำระเงินภายใน 7 วัน หากเกินกำหนดใบสั่งซื้อจะถูกยกเลิกโดยอัตโนมัติ**
        </span>
        <div v-if="status === 'waiting_for_confirmation'">
          <v-row class="ma-0">
            <v-col :cols="12" class="py-2 px-1">
              <v-text-field
                label="คำอธิบายเพิ่มเติม"
                filled
                hide-details
                v-model="order.description"
                @blur="postDesc"
              />
            </v-col>
          </v-row>
        </div>
        <div v-if="status === 'payment_check'">
          <v-row class="ma-0">
            <v-col :cols="6" class="py-2 px-1">
              <v-btn
                block
                color="#e8e8e8"
                @click="goUploadPayment(order.sales_order)"
                v-if="order.status === 'payment_uploaded'"
                class="v-btn--disabled"
                style="pointer-events: auto"
              >
                <v-icon left>$vuetify.icons.upload</v-icon> แจ้งการชำระเงิน
              </v-btn>
              <v-btn v-else block color="#e8e8e8" @click="goUploadPayment(order.sales_order)">
                <v-icon left>$vuetify.icons.upload</v-icon> แจ้งการชำระเงิน
              </v-btn>
            </v-col>
            <v-col :cols="6" class="py-2 px-1">
              <v-btn color="#e8e8e8" block @click="shareOrder">
                <v-icon left>print </v-icon>
                ดาวน์โหลดใบสั่งซื้อ
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-if="status === 'approved'">
          <v-row class="ma-0">
            <v-col :cols="12">
              <v-btn color="#2c3239" block dark @click="shareOrder">
                <v-icon left>print</v-icon>
                ดาวน์โหลดใบสั่งซื้อ
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SaleOrderHeader from '../components/SaleOrderHeader'
export default {
  name: 'SaleOrder',
  components: {
    SaleOrderHeader,
  },
  props: {
    expand: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: '',
    },
    order: {
      type: Object,
      defualt: null,
    },
    isHeader: {
      type: Boolean,
      default: false,
    },
    URL: {
      type: String,
    },
  },
  methods: {
    async postDesc() {
      const self = this
      try {
        await self.$axios({
          method: 'POST',
          url: '/api/salesorder/description',
          data: {
            social_id: self.$store.state.tokenId,
            salesorder_id: self.order.sales_order,
            description: self.order.description || '',
          },
        })
      } catch (e) {
        console.log(e)
        self.$modal.show({
          title: `ERROR: ${e}`,
          isOne: true,
        })
      }
    },
    goUploadPayment(id) {
      const self = this
      self.$router.push({ path: '/uploadpayment', query: { id: id } })
    },
    async shareOrder() {
      const self = this
      // window.location = self.URL || self.order.receipt_url
      await self.$liff.openWindow({
        url: self.URL || self.order.receipt_url,
        external: true,
      })
      self.$liff.closeWindow()
    },
  },
  computed: {
    checktype() {
      return this.order.type === 'saleorder' ? 'Sale Order' : 'ใบค่าบริการ'
    },
    checkExpand() {
      return this.expand ? 'pa-2' : 'mb-12 pa-2'
    },
    address() {
      /*eslint-disable */
      const {
        address_1,
        subdistrict,
        district,
        province,
        postal_code,
      } = this.order.merchant_detail[0].address
      const districtName = province === 'กรุงเทพมหานคร' ? `เขต ${district}` : `อำเภอ ${district}`
      const subDistrictName =
        province === 'กรุงเทพมหานคร' ? `แขวง ${subdistrict}` : `ตำบล ${subdistrict}`
      return `${address_1} ${subDistrictName} ${districtName} จังหวัด ${province} ${postal_code}`
    },
  },
}
</script>

<style lang="scss" scoped>
.totalPriceBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.alertText {
  color: #ff0000;
  font-family: db_heaventregular;
  font-size: 12px;
  display: block;
}
.bankDetail {
  display: block;
  font-family: db_heaventregular;
  font-size: 14px;
  color: #b3bdc2;
}
.borderRight {
  border-right: 1px solid #fff;
}
.subjectPrice {
  display: block;
  font-family: db_heaventregular;
  font-size: 14px;
  color: #ffffff;
}
.paymentText {
  font-family: db_heaventregular;
  font-size: 16px;
  color: #ffffff;
}
.paymentBox {
  background-color: #707070;
}
.subjectName {
  font-size: 16px;
  font-family: db_heaventmed;
  line-height: 21px;
  color: #000000;
}
.detailName {
  font-size: 16px;
  font-family: db_heaventregular;
  color: #707070;
}
.Name {
  display: block;
  font-size: 16px;
  font-family: db_heaventregular;
  line-height: 21px;
  color: #000000;
}
.header {
  background-color: #f3f3f3;
}
.orderHeader {
  background-color: #707070;
}
.statusBox {
  background-color: #fff;
}
.table {
  border-left: 1px solid #dedede;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table:first-child {
  border: 0;
}
</style>
